"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("aurum");
var Status_1 = require("../../types/Status");
var Filter = function (_a) {
    var originalQuestions = _a.questions, questionsActive = _a.questionsActive, chat = _a.chat, chatActive = _a.chatActive, socket = _a.socket;
    var _b = tslib_1.__read(aurum_1.React.useState([
        Status_1.Status.NEW.toString(),
        Status_1.Status.ACCEPTED.toString()
    ]), 2), filter = _b[0], setFilter = _b[1];
    var _c = tslib_1.__read(aurum_1.React.useState(''), 2), name = _c[0], setName = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState(''), 2), text = _d[0], setText = _d[1];
    var questions = originalQuestions
        .sort(function (a, b) {
        if (a.status !== b.status) {
            return a.status - b.status;
        }
        return a.id - b.id;
    });
    var setStatus = function (id, status) {
        socket.emit('setStatus', { id: id, status: status });
    };
    var setComment = function (id, comment) {
        socket.emit('setComment', { id: id, comment: comment });
    };
    var setQuestionsActive = function (status) {
        socket.emit('setQuestionsActive', status);
    };
    var clearQuestions = function () {
        if (window.confirm('Sollen die Fragen wirklich unwiederruflich gelöscht werden?')) {
            socket.emit('clearQuestions', null);
        }
    };
    var setChatActive = function (status) {
        socket.emit('setChatActive', status);
    };
    var clearChat = function () {
        if (window.confirm('Soll der Chat wirklich unwiederruflich geleert werden?')) {
            socket.emit('clearChat', null);
        }
    };
    var clearAdminmessage = function () {
        if (window.confirm('Sollen die Admin-Nachrichten wirklich unwiederruflich geleert werden?')) {
            socket.emit('clearAdminmessage', null);
        }
    };
    var sendAdminmessage = function () {
        if (name && text) {
            socket.emit('adminmessage', {
                name: name,
                text: text
            });
            setText('');
        }
    };
    return aurum_1.React.createElement(aurum_1.React.Fragment, null,
        aurum_1.React.createElement(aurum_1.Body, null,
            aurum_1.React.createElement(aurum_1.Sheet, { className: 'Filter' },
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Multiselect, { style: { minWidth: '200px' }, native: false, label: 'Status-Filter', value: filter, onChange: function (e) {
                            setFilter(e.target.value);
                        }, options: [
                            { id: Status_1.Status.NEW.toString(), label: 'NEW' },
                            { id: Status_1.Status.ACCEPTED.toString(), label: 'ACCEPTED' },
                            { id: Status_1.Status.DECLINED.toString(), label: 'DECLINED' },
                            { id: Status_1.Status.ANSWERED.toString(), label: 'ANSWERED' }
                        ] }),
                    aurum_1.React.createElement(aurum_1.ButtonGroup, null,
                        aurum_1.React.createElement(aurum_1.Button, { type: questionsActive ? 'success-primary' : 'error-primary', onClick: function () { return setQuestionsActive(!questionsActive); } },
                            "Fragen: ",
                            questionsActive ? 'aktiviert' : 'deaktiviert'),
                        aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () { return clearQuestions(); } },
                            aurum_1.React.createElement(aurum_1.Icon, { type: 'close' }),
                            " l\u00F6schen")),
                    aurum_1.React.createElement(aurum_1.ButtonGroup, null,
                        aurum_1.React.createElement(aurum_1.Button, { type: chatActive ? 'success-primary' : 'error-primary', onClick: function () { return setChatActive(!chatActive); } },
                            "Chat: ",
                            chatActive ? 'aktiviert' : 'deaktiviert'),
                        aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () { return clearChat(); } },
                            aurum_1.React.createElement(aurum_1.Icon, { type: 'close' }),
                            " l\u00F6schen")),
                    aurum_1.React.createElement(aurum_1.InputGroup, { span: 2 },
                        aurum_1.React.createElement(aurum_1.Input, { label: 'Admin-Name', value: name, onChange: function (e) {
                                setName(e.target.value);
                            } }),
                        aurum_1.React.createElement(aurum_1.Input, { label: 'Admin-Nachricht', value: text, onChange: function (e) {
                                setText(e.target.value);
                            }, onKeyDown: function (e) {
                                if (e.key === 'Enter') {
                                    sendAdminmessage();
                                }
                            } }),
                        aurum_1.React.createElement(aurum_1.Button, { type: 'success-primary', onClick: function () { return sendAdminmessage(); } }, "senden"),
                        aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () { return clearAdminmessage(); } },
                            aurum_1.React.createElement(aurum_1.Icon, { type: 'close' }),
                            " l\u00F6schen"))),
                aurum_1.React.createElement(aurum_1.Table, null,
                    aurum_1.React.createElement("thead", null,
                        aurum_1.React.createElement("tr", null,
                            aurum_1.React.createElement("th", null, "ID"),
                            aurum_1.React.createElement("th", null, "Status"),
                            aurum_1.React.createElement("th", null, "Name"),
                            aurum_1.React.createElement("th", null, "Frage"),
                            aurum_1.React.createElement("th", null))),
                    aurum_1.React.createElement("tbody", null, questions
                        .filter(function (_a) {
                        var status = _a.status;
                        return filter.length === 0 || filter.includes(status.toString());
                    })
                        .map(function (_a) {
                        var id = _a.id, name = _a.name, text = _a.text, status = _a.status, comment = _a.comment;
                        var className;
                        switch (status) {
                            case Status_1.Status.ACCEPTED:
                                className = 'success';
                                break;
                            case Status_1.Status.DECLINED:
                                className = 'warn';
                                break;
                            case Status_1.Status.ANSWERED:
                                className = 'primary';
                                break;
                            case Status_1.Status.DELETED:
                                className = 'error';
                                break;
                        }
                        return aurum_1.React.createElement("tr", { key: id, className: className },
                            aurum_1.React.createElement("td", null, id),
                            aurum_1.React.createElement("td", null, Status_1.Status[status]),
                            aurum_1.React.createElement("td", null, name),
                            aurum_1.React.createElement("td", null,
                                text,
                                comment ? aurum_1.React.createElement(aurum_1.React.Fragment, null,
                                    aurum_1.React.createElement("br", null),
                                    aurum_1.React.createElement("i", null,
                                        "Kommentar: ",
                                        comment)) : null),
                            aurum_1.React.createElement("td", null,
                                aurum_1.React.createElement(aurum_1.ButtonGroup, null,
                                    aurum_1.React.createElement(aurum_1.Button, { onClick: function () { return setComment(id, window.prompt('Kommentar', comment || '')); } },
                                        aurum_1.React.createElement(aurum_1.Icon, { type: 'edit' }),
                                        " Kommentar"),
                                    aurum_1.React.createElement(aurum_1.Button, { type: 'success-primary', onClick: function () { return setStatus(id, Status_1.Status.ACCEPTED); } },
                                        aurum_1.React.createElement(aurum_1.Icon, { type: 'check_circle' }),
                                        " annehmen"),
                                    aurum_1.React.createElement(aurum_1.Button, { type: 'warn-primary', onClick: function () { return setStatus(id, Status_1.Status.DECLINED); } },
                                        aurum_1.React.createElement(aurum_1.Icon, { type: 'remove_circle' }),
                                        " ablehnen"),
                                    aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () {
                                            if (window.confirm('Sollen diese Frage wirklich unwiederruflich gelöscht werden?')) {
                                                setStatus(id, Status_1.Status.DELETED);
                                            }
                                        } },
                                        aurum_1.React.createElement(aurum_1.Icon, { type: 'close' }),
                                        " l\u00F6schen"))));
                    }))))));
};
exports.default = aurum_1.React.memo(Filter);
