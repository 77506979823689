"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("aurum");
var conf_1 = require("../../conf");
var App_1 = require("./App");
var Admin = function (_a) {
    var socket = _a.socket;
    var _b = tslib_1.__read(aurum_1.React.useState({}), 2), db = _b[0], setDb = _b[1];
    aurum_1.React.useEffect(function () {
        if (socket) {
            socket.emit('adminlogin', App_1.PIN);
            socket.on('db', function (db) {
                console.log('db', db);
                setDb(db);
            });
        }
    }, [socket]);
    return aurum_1.React.createElement(aurum_1.React.Fragment, null,
        aurum_1.React.createElement(aurum_1.Body, null,
            aurum_1.React.createElement(aurum_1.Sheet, { className: 'Admin' },
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Button, { type: 'success-primary', onClick: function () {
                            socket.emit('addEvent', window.prompt('bitte Titel eingeben'));
                        } }, "neues Event"))),
            Object.values(db)
                .filter(function (entry) { return entry.key !== '1111-2222-3333-4444' && entry.key !== '6666-7777-8888-9999'; })
                .map(function (entry) {
                var _a, _b, _c, _d;
                return aurum_1.React.createElement(aurum_1.Sheet, { key: entry.key },
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement("h2", { span: 7 }, entry.title),
                        aurum_1.React.createElement(aurum_1.Button, { type: 'error-primary', onClick: function () {
                                if (window.confirm("Soll " + entry.title + " wirklich gel\u00F6scht werden?")) {
                                    socket.emit('deleteEvent', entry.key);
                                }
                            } },
                            aurum_1.React.createElement(aurum_1.Icon, { type: 'close' }),
                            " l\u00F6schen")),
                    aurum_1.React.createElement(aurum_1.Row, null,
                        aurum_1.React.createElement("div", null,
                            aurum_1.React.createElement("h3", null, "Zeugs"),
                            aurum_1.React.createElement("div", null,
                                "PIN: ",
                                aurum_1.React.createElement("strong", null, entry.pin)),
                            aurum_1.React.createElement("div", null,
                                "KEY: ",
                                aurum_1.React.createElement("strong", null, entry.key)),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("a", { href: "./#" + entry.key + "/filter" }, "Fragen filtern")),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("a", { href: "./#" + entry.key + "/answer" }, "Fragen beantworten"))),
                        aurum_1.React.createElement("div", null,
                            aurum_1.React.createElement("h3", null, "Statistiken"),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("strong", null, entry.questions.length),
                                " Fragen gestellt"),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("strong", null, (_a = entry.messagesSent) !== null && _a !== void 0 ? _a : 0),
                                " Chat-Nachrichten gesendet"),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("strong", null, (_b = entry.adminmessagesSent) !== null && _b !== void 0 ? _b : 0),
                                " Admin-Nachrichten gesendet"),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("strong", null, (_c = entry.currentUsers) !== null && _c !== void 0 ? _c : 0),
                                " aktuelle Zuschauer"),
                            aurum_1.React.createElement("div", null,
                                aurum_1.React.createElement("strong", null, (_d = entry.maxUsers) !== null && _d !== void 0 ? _d : 0),
                                " Zuschauer maximal")),
                        aurum_1.React.createElement(aurum_1.Textarea, { label: 'Fragen-Code', value: "<script>\nwindow.svqKey = '" + entry.key + "';\nwindow.svqSocketDebug = false;\nwindow.svqQuestionNamePlaceholder = 'Ihr Name';\nwindow.svqQuestionTextPlaceholder = 'Ihre Frage/Anmerkung...';\nwindow.svqQuestionConnectingMessage = 'Verbindung wird aufgebaut...';\nwindow.svqQuestionConnectionFailedMessage = 'Verbindung fehlgeschlagen. Bitte laden Sie die Seite neu.';\nwindow.svqQuestionDisabledMessage = 'Das Modul ist zur Zeit deaktiviert. Es wird aktiviert, sobald Fragen/Anmerkungen wieder zul\u00E4ssig sind.';\nwindow.svqQuestionNewQuestionLabel = 'Neue Frage stellen';\nwindow.svqQuestionSendQuestionLabel = 'Senden';\nwindow.svqQuestionNewQuestionSentMessage = 'Ihre Frage wurde erfolgreich gesendet.';\n</script>\n<script src=\"" + (conf_1.BASEURL !== null && conf_1.BASEURL !== void 0 ? conf_1.BASEURL : '') + "question.js\"></script>" }),
                        aurum_1.React.createElement(aurum_1.Textarea, { label: 'Chat-Code', value: "<script>\nwindow.svqKey = '" + entry.key + "';\nwindow.svqSocketDebug = false;\nwindow.svqChatNamePlaceholder = 'Ihr Name';\nwindow.svqChatTextPlaceholder = 'Ihre Nachricht...';\nwindow.svqChatHideTimestamps = false;\nwindow.svqChatConnectingMessage = 'Verbindung wird aufgebaut...';\nwindow.svqChatConnectionFailedMessage = 'Verbindung fehlgeschlagen. Bitte laden Sie die Seite neu.';\nwindow.svqChatDisabledMessage = 'Der Chat ist zur Zeit deaktiviert.';\nwindow.svqChatSendMessageLabel = 'Senden';\n</script>\n<script src=\"" + (conf_1.BASEURL !== null && conf_1.BASEURL !== void 0 ? conf_1.BASEURL : '') + "chat.js\"></script>" }),
                        aurum_1.React.createElement(aurum_1.Textarea, { label: 'Admin-Message-Code', value: "<script>\nwindow.svqKey = '" + entry.key + "';\nwindow.svqSocketDebug = false;\nwindow.svqAdminmessageHideTimestamps = false;\nwindow.svqAdminmessageConnectingMessage = 'Verbindung wird aufgebaut...';\nwindow.svqAdminmessageConnectionFailedMessage = 'Verbindung fehlgeschlagen. Bitte laden Sie die Seite neu.';\n</script>\n<script src=\"" + (conf_1.BASEURL !== null && conf_1.BASEURL !== void 0 ? conf_1.BASEURL : '') + "adminmessage.js\"></script>" })));
            })));
};
exports.default = aurum_1.React.memo(Admin);
