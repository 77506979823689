"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
var Status;
(function (Status) {
    Status[Status["NEW"] = 0] = "NEW";
    Status[Status["ACCEPTED"] = 1] = "ACCEPTED";
    Status[Status["DECLINED"] = 2] = "DECLINED";
    Status[Status["ANSWERED"] = 3] = "ANSWERED";
    Status[Status["DELETED"] = 4] = "DELETED";
})(Status = exports.Status || (exports.Status = {}));
