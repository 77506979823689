"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("aurum");
var Status_1 = require("../../types/Status");
var Answer = function (_a) {
    var originalQuestions = _a.questions, questionsActive = _a.questionsActive, chat = _a.chat, chatActive = _a.chatActive, socket = _a.socket;
    var _b = tslib_1.__read(aurum_1.React.useState([]), 2), questions = _b[0], setQuestions = _b[1];
    var _c = tslib_1.__read(aurum_1.React.useState(0), 2), index = _c[0], _setIndex = _c[1];
    var _d = tslib_1.__read(aurum_1.React.useState(null), 2), question = _d[0], setQuestion = _d[1];
    var setIndex = function (_index, questions) {
        if (_index === void 0) { _index = null; }
        if (question && _index == null) {
            _index = questions.findIndex(function (q) { return q.text === question.text; });
            if (_index < 0) {
                _index = index;
            }
        }
        if (_index < 0) {
            _index = questions.length - 1;
        }
        _index = _index !== null && _index !== void 0 ? _index : (index || 0);
        _index = (_index % questions.length) || 0;
        _setIndex(_index);
        setQuestion(questions[_index]);
    };
    aurum_1.React.useEffect(function () {
        var questions = originalQuestions
            .filter(function (q) { return q.status === Status_1.Status.ACCEPTED; });
        setQuestions(questions);
        setIndex(null, questions);
    }, [originalQuestions]);
    var setStatus = function (id, status) {
        setQuestion(null);
        socket.emit('setStatus', { id: id, status: status });
    };
    var setQuestionsActive = function (status) {
        socket.emit('setQuestionsActive', status);
    };
    var setChatActive = function (status) {
        socket.emit('setChatActive', status);
    };
    return aurum_1.React.createElement(aurum_1.React.Fragment, null,
        aurum_1.React.createElement(aurum_1.Body, null, question
            ? aurum_1.React.createElement(aurum_1.Sheet, { className: 'question' },
                aurum_1.React.createElement("h3", null,
                    questions.length,
                    " offene Fragen"),
                aurum_1.React.createElement("h3", null,
                    "Frage von ",
                    question.name || 'unbekannt',
                    ":"),
                aurum_1.React.createElement("h1", null, question.text),
                question.comment ? aurum_1.React.createElement("h2", null,
                    aurum_1.React.createElement("i", null,
                        "Kommentar: ",
                        question.comment)) : null)
            : aurum_1.React.createElement(aurum_1.Sheet, null,
                aurum_1.React.createElement("h1", null, "keine Frage(n) vorhanden"))),
        aurum_1.React.createElement(aurum_1.Footer, null,
            aurum_1.React.createElement(aurum_1.Sheet, null,
                aurum_1.React.createElement(aurum_1.Row, { columns: 4 },
                    aurum_1.React.createElement(aurum_1.Button, { style: { zoom: 1.5 }, onClick: function () { return setIndex(index - 1, questions); }, disabled: questions.length <= 1 },
                        aurum_1.React.createElement(aurum_1.Icon, { type: 'chevron_left' })),
                    aurum_1.React.createElement(aurum_1.Button, { style: { zoom: 1.5 }, type: 'error-primary', onClick: function () { return setStatus(question.id, Status_1.Status.DECLINED); }, disabled: !question },
                        aurum_1.React.createElement(aurum_1.Icon, { type: 'remove_circle' })),
                    aurum_1.React.createElement(aurum_1.Button, { style: { zoom: 1.5 }, type: 'primary', onClick: function () { return setStatus(question.id, Status_1.Status.ANSWERED); }, disabled: !question },
                        aurum_1.React.createElement(aurum_1.Icon, { type: 'check_circle' })),
                    aurum_1.React.createElement(aurum_1.Button, { style: { zoom: 1.5 }, onClick: function () { return setIndex(index + 1, questions); }, disabled: questions.length <= 1 },
                        aurum_1.React.createElement(aurum_1.Icon, { type: 'chevron_right' }))),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement(aurum_1.Button, { type: questionsActive ? 'success-primary' : 'error-primary', onClick: function () { return setQuestionsActive(!questionsActive); } },
                        "Fragen: ",
                        questionsActive ? 'aktiviert' : 'deaktiviert'),
                    aurum_1.React.createElement(aurum_1.Button, { type: chatActive ? 'success-primary' : 'error-primary', onClick: function () { return setChatActive(!chatActive); } },
                        "Chat: ",
                        chatActive ? 'aktiviert' : 'deaktiviert')))));
};
exports.default = aurum_1.React.memo(Answer);
